import {
  ChangeDetectorRef,
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { GetRecordingInsightsQuery } from '@app/features/summaries/components/summaries/get-recordings.onelife.generated';
import { summaryIsUnsignedStartedAppointment } from '@app/features/summaries/shared/summaries-utils';
import { Summary } from '@app/features/summaries/shared/summaries.type';
import { RecordingManagerComponent } from '../recording/recording-manager/recording-manager.component';
import { HealthscribeBannerVariant } from './v2/healthscribe-banner-v2.component';
import { RecordingStepService } from '../recording/recording-step.service';
import {
  HealthscribeRecorderService,
  RECORDING_STATE,
} from '../recording/healthscribe-recorder.service';

@Component({
  selector: 'omg-healthscribe-banner-container',
  templateUrl: './healthscribe-banner-container.component.html',
})
export class HealthscribeBannerContainerComponent implements OnChanges, OnInit {
  private recordingStepService = inject(RecordingStepService);
  bannerState = this.recordingStepService.writableBannerState;

  private healthscribeRecorderService = inject(HealthscribeRecorderService);
  recordingState = this.healthscribeRecorderService.recordingState;

  @Input() summary: Summary;
  @Input()
  appointmentRecording: GetRecordingInsightsQuery['appointmentRecording'];
  @Input()
  appointmentRecordingIsLoading: boolean;

  @Output() viewSummary = new EventEmitter<Event>();

  @ViewChild(RecordingManagerComponent)
  recordingManager: RecordingManagerComponent;

  private healthscribeFlag: boolean | undefined;
  private healthscribeInChartFlag: boolean | undefined;

  constructor(
    private launchDarkly: LaunchDarklyService,
    private changeDetector: ChangeDetectorRef,
  ) {
    effect(
      () => {
        this.recordingState();
        this.setBannerState();
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    this.setBannerState();
  }

  ngOnChanges(): void {
    // how do i set the banner state based onthe recordingState signal changing?
    this.setBannerState();
  }

  setBannerState(): void {
    if (this.appointmentRecording?.state === 'transcribed') {
      this.bannerState.set('transcriptAvailable');
    } else if (this.appointmentRecording?.state === 'transcribing') {
      this.bannerState.set('generating');
    } else if (!this.appointmentRecording) {
      this.bannerState.set('default');
    }
    if (
      this.recordingState() !== RECORDING_STATE.NONE &&
      this.recordingState() !== RECORDING_STATE.ERROR
    ) {
      this.bannerState.set('recording');
    }

    if (this.recordingState() === RECORDING_STATE.ERROR) {
      this.bannerState.set('error');
    }
  }

  showHealthscribeBanner(summary: Summary): 'none' | 'v1' | 'v2' {
    this.healthscribeFlag ??= this.launchDarkly.variation<boolean>(
      FeatureFlagNames.healthscribeBannerVersionOne,
      false,
    );
    this.healthscribeInChartFlag ??= this.launchDarkly.variation<boolean>(
      FeatureFlagNames.healthscribeInChart,
      false,
    );

    // Only show if the summary hasn't been signed yet and the appointment is in a started-adjacent state
    const shouldShow = summaryIsUnsignedStartedAppointment(summary);

    if (this.healthscribeFlag && shouldShow) {
      return 'v1';
    } else if (
      this.healthscribeInChartFlag &&
      this.bannerState() !== 'dismissed' &&
      shouldShow
    ) {
      return 'v2';
    }
    return 'none';
  }

  get bannerVariant(): HealthscribeBannerVariant {
    const state = this.bannerState();
    if (state === 'transcriptAvailable') {
      return 'success';
    } else if (state === 'default') {
      return 'instructional';
    } else {
      return state as HealthscribeBannerVariant;
    }
  }

  dismissHealthScribe(): void {
    this.recordingStepService.dismiss();
  }
  undoDismissHealthScribe(): void {
    this.recordingStepService.cancelDismiss();
  }

  startHealthScribeRecording(): void {
    this.bannerState.set('recording');
    // the recording manager is hidden behind an ngIf, so will not be available as a viewChild until detectChanges is called.
    this.changeDetector.detectChanges();
    this.recordingManager.record();
  }

  undoGenerateHealthScribe(): void {
    this.recordingStepService.cancelEndRecording();
  }
}
