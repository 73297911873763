<div
  om-layout
  om-layout-fill
  om-layout-align="space-around center"
  class="container"
  [ngClass]="{ interactive: mode === 'interactive', readonly: mode === 'readonly' }"
>
  <div *ngIf="mode === 'interactive'">
    <omg-icon-button (click)="record.emit()" *ngIf="isPaused" icon="play" library="fa" />
    <omg-icon-button (click)="pause.emit()" *ngIf="isRecording" icon="pause" library="fa" />
  </div>
  <div class="text" data-cy="recording-progress" *ngIf="!hasError; else error">
    <span *ngIf="isPaused">
      <span class="recording-icon om-icon icon-recording -gray"></span>
      Paused
    </span>
    <span *ngIf="isStartingStream">
      <span class="recording-icon om-icon icon-recording -red"></span>
      Starting stream...
    </span>
    <span *ngIf="isRecording">
      <span class="recording-icon om-icon icon-recording -red"></span>
      Recording
    </span>
    <span *ngIf="duration" class="duration">
      {{ duration | date: 'mm:ss' : 'UTC' }}
    </span>
  </div>
  <button
    class="end-button"
    data-cy="transcribe-button"
    omgButton
    (click)="end.emit()"
    *ngIf="mode === 'interactive'"
    variant="flat"
  >
    End and Summarize
  </button>
</div>
<ng-template #error>
  <span data-cy="recording-progress-error">
    <span class="recording-icon fa fa-exclamation-triangle"></span>
    Scribe failed
  </span>
</ng-template>
