import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { recordingExpirationString } from '../../healthscribe.utils';
import { toSignal } from '@angular/core/rxjs-interop';
import { MediaRecorderApiService } from '@app/features/healthscribe/recording/media-recorder-api.service';
import { GetRecordingInsightsQuery } from '@app/features/summaries/components/summaries/get-recordings.onelife.generated';

export type HealthscribeBannerVariant =
  | 'instructional'
  | 'success'
  | 'dismissing'
  | 'aboutToGenerate'
  | 'error'
  | 'generating';

@Component({
  selector: 'omg-healthscribe-banner-v2',
  templateUrl: './healthscribe-banner-v2.component.html',
  styleUrls: ['./healthscribe-banner-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthscribeBannerV2Component {
  #mediaRecorderApi = inject(MediaRecorderApiService);
  @Input() variant: HealthscribeBannerVariant;

  @Input()
  recording: GetRecordingInsightsQuery['appointmentRecording'];

  @Output() startRecording = new EventEmitter<Event>();
  @Output() viewSummary = new EventEmitter<Event>();
  @Output() dismiss = new EventEmitter<Event>();
  @Output() undoDismiss = new EventEmitter<Event>();
  @Output() undoGenerate = new EventEmitter<Event>();

  get expiration(): string {
    return recordingExpirationString(this.recording);
  }

  micPermission = toSignal(
    this.#mediaRecorderApi.getPermissionState$('microphone' as PermissionName),
  );
}
