<div data-cy="healthscribe-banner-v2">
  @if (variant === 'instructional') {
    <div
      om-layout
      om-layout-align="space-between center"
      data-cy="healthscribe-banner-v2"
      class="instructional banner-container"
    >
      <div>Record and summarize visit</div>
      <div om-layout om-layout-align="end center">
        <button
          omgButton
          type="button"
          variant="primary"
          data-cy="start-recording-button"
          data-testid="start-recording-button"
          [disabled]="micPermission() === 'denied'"
          (click)="startRecording.emit($event)"
        >
          Start Recording
        </button>
        <omg-icon-button
          icon="close"
          library="om-icon"
          (keypress)="dismiss.emit($event)"
          (click)="dismiss.emit($event)"
          data-testid="dismiss-button"
        ></omg-icon-button>
      </div>
    </div>
  } @else if (variant === 'dismissing') {
    <div om-layout om-layout-align="space-between center" class="dismissing banner-container">
      <div>HealthScribe dismissed</div>
      <button
        omgButton
        type="button"
        variant="flat"
        data-testid="undo-dismiss-healthscribe-button"
        (click)="undoDismiss.emit($event)"
      >
        Undo
      </button>
    </div>
  } @else if (variant === 'generating' || variant === 'aboutToGenerate') {
    <div om-layout om-layout-align="space-between center" class="dismissing banner-container">
      <div>
        <i class="fa-regular fa-spinner fa-spin-pulse"></i>
        Generating Summary.
      </div>
      @if (variant === 'aboutToGenerate') {
        <button
          omgButton
          type="button"
          variant="flat"
          data-testid="undo-dismiss-healthscribe-button"
          (click)="undoGenerate.emit($event)"
        >
          Resume Recording
        </button>
      }
    </div>
  } @else if (variant === 'success') {
    <div om-layout om-layout-align="space-between center" class="success banner-container">
      <div>
        <i class="om-icon icon-magic"></i>
        <strong>Summary Ready</strong>
        <i>{{ expiration }}</i>
      </div>
      <button
        omgButton
        type="button"
        variant="flat"
        data-cy="view-summary-button"
        data-testid="view-summary-button"
        (click)="viewSummary.emit($event)"
      >
        View Summary
      </button>
    </div>
  } @else if (variant === 'error') {
    <div om-layout om-layout-align="space-between center" class="error banner-container">
      <div>
        <p>
          <strong>Scribe failed</strong>
          You can restart the recording, but unfortunately the previous recording was lost
        </p>
      </div>
      <div om-layout om-layout-align="end center">
        <button
          omgButton
          type="button"
          variant="link"
          data-cy="restart-recording-button"
          data-testid="restart-recording-button"
          [disabled]="micPermission() === 'denied'"
          (click)="startRecording.emit($event)"
        >
          Restart Recording
        </button>
        <omg-icon-button
          icon="close"
          library="om-icon"
          (keypress)="dismiss.emit($event)"
          (click)="dismiss.emit($event)"
          data-testid="dismiss-button"
        ></omg-icon-button>
      </div>
    </div>
  }
</div>
